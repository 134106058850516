.loading-spinner {
    position: fixed;
    left: calc(50% - 2em);
    top: calc(50% - 2em);
    height: 4em;
    background-color: transparent;
    z-index: 12;
}

.screen-dimmer {
    z-index: 11;
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgba(255,255,255,0.5);
}