.alert {
    margin-bottom: 1.5em;
}

.whitelist-device-form-container {
    margin-top: 2em;
}

.whitelist-device-form-container .error-text{
    color: red;
    font-size: small;
}

.whitelist-device-form-container form .submit-button {
    margin-top: 1em;
    margin-bottom: 2em;
}

.whitelist-device-form-container form .form-field {
    margin-bottom: 1em;
}

.whitelist-device-form-container form .form-field.location-field {
    max-width: 392px;
}


@media (max-width: 900px) { 
    .whitelist-device-form-container form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .whitelist-device-form-container form .form-field {
        min-width: 75%;
    }
}