.carrierInfo-container {
    margin-bottom: 1em;
}

.carrierInfo-container a-heading[size="1"] {
    font-size: 3em;
}
.carrierInfo-container a-heading[size="2"] {
    font-size: 2em;
}

.carrierInfo-container .footnote {
    margin-top: 2.5em;
}