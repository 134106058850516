.app-details {
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px dotted #a6a6a6;
    border-radius: 1em;
    margin-bottom: 1em;
}

@media (min-width: 900px) { 
    .app-details {
        flex-direction: row;
    }
}
.app-details .app-name,
.app-details .app-link {
    margin-right: 1em;
}

.app-details .app-link-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.app-details .app-link .ios-icon {
    width: 7em;
}

.app-details .app-link .android-icon {
    width: 7.1em;
}