.register-page{
    padding: 1em;
}

@media (min-width: 900px) { 
    .register-page {
        padding-top: 3em;
        margin-right: 15%;
        margin-left: 15%;
        display: flex;
    }

    .whitelist-device-form-container{
        margin-left: 5em;
        width: 50%;
    }

    .carrierInfo-container{
        max-width: 60%;
    }
}
