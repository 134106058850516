

.footer .footer-inside {
    margin: 3em 5em;
}

.footer .footer-logo {
    width: 15em;
}

