.appList-container {
    display: flex;
    flex-direction: column;
    margin: 1em;
}

.apps-heading {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}
