
.header-container  {
    box-shadow: 0 4px 2px -2px rgba(0,0,0,.05);
    margin-bottom: 1em;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    padding: 30px 0;
}

.header-container .att-logo {
    width: 15em;
    height: 60px; /* Fixes issue in Safari where it doesn't scale height properly. */
}
.header-container .asurion-logo {
    width: 10em;
    height: 60px; /* Fixes issue in Safari where it doesn't scale height properly. */
}

@media (min-width: 900px) { 
    .header-container  {
        padding: 15px 30px;
        display: block;   
    }
}