.success-page{
    padding: 1em;
}

@media (min-width: 900px) { 
        .success-page {
            padding-top: 3em;
            margin-right: 15%;
            margin-left: 15%;
            display: flex;
        }
    
        .apps-container {
            margin-top: -2em;
            margin-left: 5em;
        }
    
        .success-page .carrierInfo-container{
            min-width: 40%;
            max-width: 70%;
        }
    }