* {
    background-color: #f9f9f9;
}

body {
    margin: 0;
}

.footer-spacing-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between
}

.header-and-body {
    min-height: 100vh;
}

.grecaptcha-badge {
    z-index: 1000; /* To show it over the atomic-ui input boxes*/
}

li {
    margin-bottom: 10px;
}